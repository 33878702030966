export const LOGIN_CARD = {
    FOOTER: 'Term of use. Privacy policy',
    MESSAGE: 'Welcome back2! Please login to your account.',
    TITLE: 'Log in to Motion Software',
    SUBTITLE: (signUpButtonLink) => [
        `Glad to see you back! Haven't joined yet?`,
        <a className='signup-ref' href={signUpButtonLink}>
            {' '}
            Sign up now{' '}
        </a>,
    ],
};
