import { push } from 'connected-react-router';
import { getHomePath } from 'utils/navigationUtils';
import { generateNumericHash, parseQueryString } from 'utils/helpers';
import agencyService from 'services/agencyService';
import employeeService from 'services/employeeService';

import { showNotification } from 'actions/notificationActions';
import {
    showApplicationLoader,
    hideApplicationLoader,
} from 'actions/applicationLoaderActions';
import {
    tasksDataFetchSuccess,
    tasksDataFetchingSuccess,
    fetchEmployeesPending,
    fetchEmployeesSuccess,
} from './sharedActions';
import { authorizedShowNotification } from 'actions/actionHelpers';

import { logEvent } from 'utils/amplitude';

import {
    AGENCY_MESSAGES,
    HOLIDAYS_MESSAGES,
    AUTH_MESSAGES,
    TASK_MESSAGES,
    DASHBOARD_MESSAGES,
    MAIN_MENU_MESSAGES,
    DOCUMENTS_MESSAGES,
} from 'constants/messageConstants';
import {
    NOTIFICATION_TYPES,
    NOTIFICATION_CLICKAWAY,
} from 'constants/notificationTypes';
import { HOLIDAY_REQUEST_CONSTANTS } from 'constants/actionsConstants';
import { HTTP_STATUS_CODES } from 'constants/httpStatusCodesConstants';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';

import {
    EVROTRUST_AGENCY_INFO_UPDATE_SUCCESS,
    EVROTRUST_AGENCY_INFO_FETCH_SUCCESS,
    EVROTRUST_AGENCY_INTEGRATION_FETCH_SUCCESS,
    CALENDLY_AGENCY_INFO_UPDATE_SUCCESS,
    CALENDLY_AGENCY_INFO_FETCH_SUCCESS,
    BASIC_AGENCY_INFO_UPDATE_SUCCESS,
    AGENCY_INFO_FETCH_SUCCESS,
    AGENCY_LOGO_UPLOAD_SUCCESS,
    AGENCY_LOGO_DELETE_SUCCESS,
    AGENCY_EMPLOYEE_SETTINGS_FETCH_SUCCESS,
    AGENCY_HOLIDAY_ALLOWANCE_UPDATE_SUCCESS,
    AGENCY_PERFORMANCE_CRITERIAS_UPDATE_SUCCESS,
    AGENCY_TIMESHEETS_FETCH_SUCCESS,
    AGENCY_TIMESHEETS_EXPORT_SUCCESS,
    AGENCY_PENDING_HOLIDAYS_FETCH_SUCCESS,
    AGENCY_PENDING_HOLIDAYS_REPLACE_SUCCESS,
    AGENCY_HOLIDAYS_REJECT_REQUEST_SUCCESS,
    AGENCY_HOLIDAYS_APPROVE_REQUESTS_SUCCESS,
    AGENCY_PERFORMANCE_CRITERIAS_FETCH_SUCCESS,
    AGENCY_IMPORTANT_DOCUMENTS_UPDATE_SUCCESS,
    AGENCY_APPROVED_HOLIDAYS_FETCH_SUCCESS,
    AGENCY_REVIEWED_HOLIDAYS_FETCH_SUCCESS,
    AGENCY_REVIEWED_HOLIDAYS_REPLACE_SUCCESS,
    AGENCY_SICK_LEAVES_FETCH_SUCCESS,
    AGENCY_WORK_LEAVES_FETCH_SUCCESS,
    AGENCY_HOME_INFORMATION_FETCH_SUCCESS,
    AGENCY_CLIENT_HOME_INFORMATION_FETCH_SUCCESS,
    AGENCY_EMPLOYEE_HOME_INFORMATION_FETCH_SUCCESS,
    AGENCY_SUPPLIER_HOME_INFORMATION_FETCH_SUCCESS,
    HOME_INFORMATION_INITIAL_STATE_SET,
    AGENCY_ADMINS_FETCH_SUCCESS,
    AGENCY_PENDING_HOLIDAYS_COUNT_FETCH_SUCCESS,
    AGENCY_IMPORTANT_DOCUMENTS_FETCH_SUCCESS,
    AGENCY_TRAININGS_FETCH_SUCCESS,
    AGENCY_TRAININGS_ADD_SUCCESS,
    AGENCY_TRAINING_REMOVE_SUCCESS,
    AGENCY_TRAINING_UPDATE_SUCCESS,
    TRAINING_TOPIC_ADD_SUCCESS,
    TRAINING_TOPIC_REMOVE_SUCCESS,
    TRAINING_FETCH_SUCCESS,
    TRAINING_TOPIC_UPDATE_SUCCESS,
    AGENCY_PROJECTS_FETCH_SUCCESS,
    ONBOARDING_FETCH_SUCCESS,
    ONBOARDING_UPDATE_SUCCESS,
    CLIENT_INV_STATUS_DONE_FETCH_SUCCESS,
    KNOW_YOUR_TEAM_SAMPLE_FETCH_SUCCESS,
    AGENCY_RECRUITER_HOME_INFORMATION_FETCH_SUCCESS,
    ALL_TASKS_FETCH_SUCCESS,
    AGENCY_TASK_EDIT_SUCCESS,
    AGENCY_TASK_DELETE_SUCCESS,
    TASK_ADD_SUCCESS,
    ADMIN_DASHBOARD_CANDIDATES_FETCH_SUCCESS,
    TERMS_AND_CONDITIONS_FETCH_SUCCESS,
    TERMS_AND_CONDITIONS_UPDATE_SUCCESS,
    AGENCY_NAME_FETCH_SUCCESS,
    DELETED_USERS_FETCH_SUCCESS,
    DELETED_USERS_REPLACE_SUCCESS,
    EVROTRUST_AGENCY_USAGE_UPDATE_SUCCESS,
    UI_DEPENDENCIES_FETCH_SUCCESS,
    AGENCY_MAIN_ADMIN_FETCH_SUCCESS,
    ADMIN_DASHBOARD_DEDICATED_POSITIONS_FETCH_SUCCESS,
    ADMIN_DASHBOARD_ON_DEMAND_POSITIONS_FETCH_SUCCESS,
    ADMIN_DASHBOARD_EMPLOYEES_BY_COUNTRY_FETCH_SUCCESS,
    ADMIN_DASHBOARD_VETTED_CANDIDATES_COUNT_FETCH_SUCCESS,
    ADMIN_DASHBOARD_REJECTED_CANDIDATES_COUNT_FETCH_SUCCESS,
    ADMIN_DASHBOARD_PLACED_EMPLOYEES_COUNT_FETCH_SUCCESS,
    ADMIN_DASHBOARD_BENCHED_EMPLOYEES_COUNT_FETCH_SUCCESS,
    ADMIN_DASHBOARD_MANAGEMENT_EMPLOYEES_COUNT_FETCH_SUCCESS,
    AGENCY_HOLIDAYS_REQUESTS_UPDATE_SUCCESS,
    DASHBOARD_DATA_FETCHING_SUCCESS,
    DASHBOARD_DATA_FETCH_SUCCESS,
    ACTIVE_HOLIDAYS_TABLE_DATA_FETCH_SUCCESS,
    ACTIVE_HOLIDAYS_TABLE_DATA_FETCHING_SUCCESS,
    SICK_LEAVES_DATA_FETCH_SUCCESS,
    SICK_LEAVES_DATA_FETCHING_SUCCESS,
    FETCH_MAIN_MENU_INFORMATION_SUCCESS,
    UPDATE_MAIN_MENU_INFORMATION_SUCCESS,
    CANDIDATE_JOURNEY_START_INFORMATION_FETCH_SUCCESS,
    INITIAL_JOURNEY_FETCH_SUCCESS,
    INITIAL_JOURNEY_UPDATE_SUCCESS,
    INITIAL_JOURNEY_CURRENT_STEP_ID_UPDATE_SUCCESS,
    AGENCY_SETTINGS_FETCH_SUCCESS,
    AGENCY_SETTINGS_UPDATE_SUCCESS,
} from 'actions/actionTypes';
import { ROLES } from 'constants/userConstants';
import { HOLIDAY_TYPES } from 'components/Person/Holidays/HolidaysRequests/HolidayRequestDialog/HolidayRequestDialogConstants';

const updateAgencyEvrotrustInfoSuccess = (evrotrustAgencyInfo) => ({
    type: EVROTRUST_AGENCY_INFO_UPDATE_SUCCESS,
    payload: evrotrustAgencyInfo,
});

const updateAgencyIsUsingEvrotrustSuccess = (isUsingEvrotrustInfo) => ({
    type: EVROTRUST_AGENCY_USAGE_UPDATE_SUCCESS,
    payload: isUsingEvrotrustInfo,
});

const fetchAgencyEvrotrustInfoSuccess = (evrotrustAgencyInfo) => ({
    type: EVROTRUST_AGENCY_INFO_FETCH_SUCCESS,
    payload: evrotrustAgencyInfo,
});

const fetchAgencyEvrotrustIntegrationSuccess = (
    evrotrustAgencyIntegration
) => ({
    type: EVROTRUST_AGENCY_INTEGRATION_FETCH_SUCCESS,
    payload: evrotrustAgencyIntegration,
});

const updateAgencyCalendlyInfoSuccess = (calendlyAgencyInfo) => ({
    type: CALENDLY_AGENCY_INFO_UPDATE_SUCCESS,
    payload: calendlyAgencyInfo,
});

export const fetchAgencyCalendlyInfoSuccess = (calendlyAgencyInfo) => ({
    type: CALENDLY_AGENCY_INFO_FETCH_SUCCESS,
    payload: calendlyAgencyInfo,
});

const updateAgencyBasicInfoSuccess = (basicAgencyInfo) => ({
    type: BASIC_AGENCY_INFO_UPDATE_SUCCESS,
    payload: basicAgencyInfo,
});

export const fetchAgencyBasicInfoSuccess = (agencyInfo) => ({
    type: AGENCY_INFO_FETCH_SUCCESS,
    payload: agencyInfo,
});

const fetchAgencyEmployeeSettingsSuccess = (employeeSettings) => ({
    type: AGENCY_EMPLOYEE_SETTINGS_FETCH_SUCCESS,
    payload: employeeSettings,
});

const uploadAgencyLogoSuccess = (logo) => ({
    type: AGENCY_LOGO_UPLOAD_SUCCESS,
    payload: logo,
});

const deleteAgencyLogoSuccess = () => ({
    type: AGENCY_LOGO_DELETE_SUCCESS,
});

const updateHolidayAllowanceSuccess = (updatedHolidayAllowance) => ({
    type: AGENCY_HOLIDAY_ALLOWANCE_UPDATE_SUCCESS,
    payload: updatedHolidayAllowance,
});

const updatePerformanceCriteriasSuccess = (updatedPerformanceCriterias) => ({
    type: AGENCY_PERFORMANCE_CRITERIAS_UPDATE_SUCCESS,
    payload: updatedPerformanceCriterias,
});

const updateImportantDocumentsSuccess = (uploadedDocuments) => ({
    type: AGENCY_IMPORTANT_DOCUMENTS_UPDATE_SUCCESS,
    payload: uploadedDocuments,
});

//Time management
const fetchAgencyTimesheetsSuccess = (timesheets) => ({
    type: AGENCY_TIMESHEETS_FETCH_SUCCESS,
    payload: timesheets,
});

const exportAgencyTimesheetsSuccess = () => ({
    type: AGENCY_TIMESHEETS_EXPORT_SUCCESS,
});

const fetchPendingHolidaysSuccess = (holidays) => ({
    type: AGENCY_PENDING_HOLIDAYS_FETCH_SUCCESS,
    payload: holidays,
});

const replacePendingHolidaysSuccess = (holidays) => ({
    type: AGENCY_PENDING_HOLIDAYS_REPLACE_SUCCESS,
    payload: holidays,
});

const rejectHolidayRequestSuccess = (request) => ({
    type: AGENCY_HOLIDAYS_REJECT_REQUEST_SUCCESS,
    payload: request,
});

const approveHolidayRequestsSuccess = (request) => ({
    type: AGENCY_HOLIDAYS_APPROVE_REQUESTS_SUCCESS,
    payload: request,
});

export const fetchPerformanceCriteriasSuccess = (request) => ({
    type: AGENCY_PERFORMANCE_CRITERIAS_FETCH_SUCCESS,
    payload: request,
});

export const fetchApprovedHolidaysSuccess = (holidays) => ({
    type: AGENCY_APPROVED_HOLIDAYS_FETCH_SUCCESS,
    payload: holidays,
});

export const fetchReviewedHolidaysSuccess = (holidays) => ({
    type: AGENCY_REVIEWED_HOLIDAYS_FETCH_SUCCESS,
    payload: holidays,
});

export const replaceReviewedHolidaysSuccess = (holidays) => ({
    type: AGENCY_REVIEWED_HOLIDAYS_REPLACE_SUCCESS,
    payload: holidays,
});

export const fetchSickLeavesSuccess = (holidays) => ({
    type: AGENCY_SICK_LEAVES_FETCH_SUCCESS,
    payload: holidays,
});

const fetchWorkLeavesSucces = (workLeaves) => ({
    type: AGENCY_WORK_LEAVES_FETCH_SUCCESS,
    payload: workLeaves,
});

export const fetchAgencyHomeInformationSuccess = (menuInformation) => ({
    type: AGENCY_HOME_INFORMATION_FETCH_SUCCESS,
    payload: menuInformation,
});

export const fetchPendingHolidaysCountSuccess = (pendingHolidaysCount) => ({
    type: AGENCY_PENDING_HOLIDAYS_COUNT_FETCH_SUCCESS,
    payload: pendingHolidaysCount,
});

export const fetchImportantDocumentsSuccess = (importantDocuments) => ({
    type: AGENCY_IMPORTANT_DOCUMENTS_FETCH_SUCCESS,
    payload: importantDocuments,
});

export const fetchClientHomeInformationSuccess = (menuInformation) => ({
    type: AGENCY_CLIENT_HOME_INFORMATION_FETCH_SUCCESS,
    payload: menuInformation,
});

export const fetchEmployeeHomeInformationSuccess = (menuInformation) => ({
    type: AGENCY_EMPLOYEE_HOME_INFORMATION_FETCH_SUCCESS,
    payload: menuInformation,
});

export const fetchSupplierHomeInformationSuccess = (menuInformation) => ({
    type: AGENCY_SUPPLIER_HOME_INFORMATION_FETCH_SUCCESS,
    payload: menuInformation,
});

export const fetchRecruiterHomeInformationSuccess = (menuInformation) => ({
    type: AGENCY_RECRUITER_HOME_INFORMATION_FETCH_SUCCESS,
    payload: menuInformation,
});

const fetchAdminsSuccess = (adminsData) => ({
    type: AGENCY_ADMINS_FETCH_SUCCESS,
    payload: adminsData,
});

export const setHomeInformationInitialState = () => ({
    type: HOME_INFORMATION_INITIAL_STATE_SET,
});

const addAgencyTrainingSuccess = (newTraining) => ({
    type: AGENCY_TRAININGS_ADD_SUCCESS,
    payload: newTraining,
});

const removeAgencyTrainingSuccess = (payload) => ({
    type: AGENCY_TRAINING_REMOVE_SUCCESS,
    payload,
});

const fetchAgencyTrainingsSuccess = (trainings) => ({
    type: AGENCY_TRAININGS_FETCH_SUCCESS,
    payload: trainings,
});

const updateAgencyTrainingSuccess = (training, trainingId) => ({
    type: AGENCY_TRAINING_UPDATE_SUCCESS,
    payload: training,
    trainingId,
});

const fetchIndividualTrainingSuccess = (payload) => ({
    type: TRAINING_FETCH_SUCCESS,
    payload,
});

const addTrainingTopicSuccess = (payload, trainingId) => ({
    type: TRAINING_TOPIC_ADD_SUCCESS,
    payload,
    trainingId,
});

const removeTrainingTopicSuccess = (payload) => ({
    type: TRAINING_TOPIC_REMOVE_SUCCESS,
    payload,
});

const updateTrainingTopicSuccess = (payload, trainingId, topicId) => ({
    type: TRAINING_TOPIC_UPDATE_SUCCESS,
    payload,
    trainingId,
    topicId,
});

const agencyProjectsFetchSuccess = (payload) => ({
    type: AGENCY_PROJECTS_FETCH_SUCCESS,
    payload,
});

export const fetchOnboardingSuccess = (isOnboardingPassed) => ({
    type: ONBOARDING_FETCH_SUCCESS,
    payload: isOnboardingPassed,
});

export const fetchClientsInvoicingStatusDoneSuccess = (data) => ({
    type: CLIENT_INV_STATUS_DONE_FETCH_SUCCESS,
    payload: data,
});

const updateOnboardingSuccess = (updateData) => ({
    type: ONBOARDING_UPDATE_SUCCESS,
    payload: updateData,
});

export const fetchKnowYourTeamSampleOnLoginSuccess = (employeesData) => ({
    type: KNOW_YOUR_TEAM_SAMPLE_FETCH_SUCCESS,
    payload: employeesData,
});

const fetchAgencyTasksSuccess = (tasks) => ({
    type: ALL_TASKS_FETCH_SUCCESS,
    payload: tasks,
});

const editTaskSuccess = (task) => ({
    type: AGENCY_TASK_EDIT_SUCCESS,
    payload: task,
});

const deleteTaskSuccess = (taskId) => ({
    type: AGENCY_TASK_DELETE_SUCCESS,
    payload: taskId,
});

const createTaskSuccess = (task) => ({
    type: TASK_ADD_SUCCESS,
    payload: task,
});

const fetchAdminDashboardCandidatesSuccess = (candidatesData) => ({
    type: ADMIN_DASHBOARD_CANDIDATES_FETCH_SUCCESS,
    payload: candidatesData,
});

const fetchAdminDashboardContractPositionsSuccess = (contractPositions) => ({
    type: ADMIN_DASHBOARD_ON_DEMAND_POSITIONS_FETCH_SUCCESS,
    payload: contractPositions,
});

const fetchAdminDashboardEmployeesByCountrySuccess = (employees) => ({
    type: ADMIN_DASHBOARD_EMPLOYEES_BY_COUNTRY_FETCH_SUCCESS,
    payload: employees,
});

export const dashboardDataFetchSuccess = () => ({
    type: DASHBOARD_DATA_FETCH_SUCCESS,
});

export const dashboardDataFetchingSuccess = () => ({
    type: DASHBOARD_DATA_FETCHING_SUCCESS,
});

export const fetchFulltimePositionsSuccess = (fulltimePositions) => ({
    type: ADMIN_DASHBOARD_DEDICATED_POSITIONS_FETCH_SUCCESS,
    payload: fulltimePositions,
});

export const fetchTermsAndConditionsSucces = (data) => ({
    type: TERMS_AND_CONDITIONS_FETCH_SUCCESS,
    payload: data,
});

const termsAndConditionsUpdateSuccess = (data) => ({
    type: TERMS_AND_CONDITIONS_UPDATE_SUCCESS,
    payload: data,
});

export const fetchAgencyNameSuccess = (data) => ({
    type: AGENCY_NAME_FETCH_SUCCESS,
    payload: data,
});

const fetchAgencyMainAdminSucces = (data) => ({
    type: AGENCY_MAIN_ADMIN_FETCH_SUCCESS,
    payload: data,
});

const fetchDeletedUsersSuccess = (data) => ({
    type: DELETED_USERS_FETCH_SUCCESS,
    payload: data,
});

const replaceDeletedUsersSuccess = (data) => ({
    type: DELETED_USERS_REPLACE_SUCCESS,
    payload: data,
});

export const fetchAgencyUiDependenciesSuccess = (data) => ({
    type: UI_DEPENDENCIES_FETCH_SUCCESS,
    payload: data,
});

const fetchVettedCandidatesCountSuccess = (data) => ({
    type: ADMIN_DASHBOARD_VETTED_CANDIDATES_COUNT_FETCH_SUCCESS,
    payload: data,
});

const fetchRejectedCandidatesCountSuccess = (data) => ({
    type: ADMIN_DASHBOARD_REJECTED_CANDIDATES_COUNT_FETCH_SUCCESS,
    payload: data,
});

const fetchAdminDashboardPlacedEmployeesCountSuccess = (data) => ({
    type: ADMIN_DASHBOARD_PLACED_EMPLOYEES_COUNT_FETCH_SUCCESS,
    payload: data,
});

const fetchAdminDashboardBenchedEmployeesCountSuccess = (data) => ({
    type: ADMIN_DASHBOARD_BENCHED_EMPLOYEES_COUNT_FETCH_SUCCESS,
    payload: data,
});

const fetchAdminDashboardManagementEmployeesCountSuccess = (data) => ({
    type: ADMIN_DASHBOARD_MANAGEMENT_EMPLOYEES_COUNT_FETCH_SUCCESS,
    payload: data,
});

const agencyHolidaysRequestsUpdateSuccess = (data) => ({
    type: AGENCY_HOLIDAYS_REQUESTS_UPDATE_SUCCESS,
    payload: data,
});

export const approvedHolidaysTableDataFetchSuccess = () => ({
    type: ACTIVE_HOLIDAYS_TABLE_DATA_FETCH_SUCCESS,
});

export const approvedHolidaysTableDataFetchingSuccess = () => ({
    type: ACTIVE_HOLIDAYS_TABLE_DATA_FETCHING_SUCCESS,
});

const sickLeavesDataFetchSuccess = () => ({
    type: SICK_LEAVES_DATA_FETCH_SUCCESS,
});

const sickLeavesDataFetchingSuccess = () => ({
    type: SICK_LEAVES_DATA_FETCHING_SUCCESS,
});

export const fetchMainMenuInformationSuccess = (data) => ({
    type: FETCH_MAIN_MENU_INFORMATION_SUCCESS,
    payload: data,
});

export const updateMainMenuInformationSuccess = (data) => ({
    type: UPDATE_MAIN_MENU_INFORMATION_SUCCESS,
    payload: data,
});

export const fetchCandidateJourneyStartInformationFetchSuccess = (data) => ({
    type: CANDIDATE_JOURNEY_START_INFORMATION_FETCH_SUCCESS,
    payload: data,
});

export const initialJourneyFetchSuccess = (result) => ({
    type: INITIAL_JOURNEY_FETCH_SUCCESS,
    payload: result,
});

export const initialJourneyUpdateSuccess = (result) => ({
    type: INITIAL_JOURNEY_UPDATE_SUCCESS,
    payload: result,
});

export const initialJourneyUpdateCurrentStepIdSuccess = (result) => ({
    type: INITIAL_JOURNEY_CURRENT_STEP_ID_UPDATE_SUCCESS,
    payload: result,
});

const agencySettingsFetchSuccess = (result) => ({
    type: AGENCY_SETTINGS_FETCH_SUCCESS,
    payload: result,
});

const agencySettingsUpdateSuccess = (result) => ({
    type: AGENCY_SETTINGS_UPDATE_SUCCESS,
    payload: result,
});

export const updateAgencyBasicInfo =
    (agencyId, basicInfo) => async (dispatch) => {
        try {
            const newBasicInfo = await agencyService.updateBasicInfo(
                agencyId,
                basicInfo
            );
            dispatch(updateAgencyBasicInfoSuccess(newBasicInfo));
            dispatch(
                updateMainMenuInformationSuccess({
                    menuSubInfo: newBasicInfo.name,
                })
            );
            dispatch(
                showNotification(
                    AGENCY_MESSAGES.UPDATE_BASIC_INFO_SUCCESS,
                    NOTIFICATION_TYPES.SUCCESS
                )
            );
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.UPDATE_BASIC_INFO_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const fetchAgencyBasicInfo = (agencyId) => async (dispatch) => {
    try {
        const agencyInfo = await agencyService.fetchAgencyInfo(agencyId);
        dispatch(fetchAgencyBasicInfoSuccess(agencyInfo));
    } catch (error) {
        dispatch(
            authorizedShowNotification(error)(
                AGENCY_MESSAGES.FETCH_BASIC_INFO_FAIL,
                NOTIFICATION_TYPES.ERROR
            )
        );
    }
};

export const uploadAgencyLogo =
    (croppedImgData, agencyId) => async (dispatch) => {
        try {
            const resultUploadedPictureObject = await agencyService.uploadLogo(
                croppedImgData,
                agencyId
            );
            logEvent(AMPLITUDE_EVENT_TYPES.ADD_AGENCY_LOGO);
            const { logoImgUrl } = resultUploadedPictureObject;
            dispatch(
                updateMainMenuInformationSuccess({
                    menuAvatar: logoImgUrl,
                    companyLogoUrl: logoImgUrl,
                })
            );
            dispatch(uploadAgencyLogoSuccess(resultUploadedPictureObject));
            dispatch(
                showNotification(
                    AGENCY_MESSAGES.UPDATE_AGENCY_LOGO_SUCCESS,
                    NOTIFICATION_TYPES.SUCCESS
                )
            );
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.UPDATE_AGENCY_LOGO_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const deleteAgencyLogo = (agencyId) => async (dispatch) => {
    try {
        await agencyService.deleteLogo(agencyId);
        dispatch(deleteAgencyLogoSuccess());
        dispatch(
            showNotification(
                AGENCY_MESSAGES.DELETE_AGENCY_LOGO_SUCCESS,
                NOTIFICATION_TYPES.SUCCESS
            )
        );
    } catch (error) {
        dispatch(
            authorizedShowNotification(error)(
                AGENCY_MESSAGES.DELETE_AGENCY_LOGO_FAIL,
                NOTIFICATION_TYPES.ERROR
            )
        );
    }
};

export const fetchAgencyEmployeeSettings = (agencyId) => async (dispatch) => {
    try {
        const employeeSettings = await agencyService.fetchEmployeeSettings(
            agencyId
        );
        dispatch(fetchAgencyEmployeeSettingsSuccess(employeeSettings));
    } catch (error) {
        dispatch(
            authorizedShowNotification(error)(
                AGENCY_MESSAGES.FETCH_EMPLOYEE_SETTINGS_FAIL,
                NOTIFICATION_TYPES.ERROR
            )
        );
    }
};

export const updateHolidayAllowance =
    (holidayAllowance, agencyId) => async (dispatch) => {
        try {
            const updatedHolidayAllowance =
                await agencyService.updateHolidayAllowance(
                    holidayAllowance,
                    agencyId
                );

            logEvent(AMPLITUDE_EVENT_TYPES.ADD_EMPLOYEE_HOLIDAY_ALLOWANCE);
            dispatch(updateHolidayAllowanceSuccess(updatedHolidayAllowance));
            dispatch(
                showNotification(
                    AGENCY_MESSAGES.UPDATE_HOLIDAY_ALLOWANCE_SUCCESS,
                    NOTIFICATION_TYPES.SUCCESS
                )
            );
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.UPDATE_HOLIDAY_ALLOWANCE_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const updatePerformanceCriterias =
    (criterias, agencyId, updateState = true) =>
    async (dispatch) => {
        try {
            const updatedCriterias =
                await agencyService.updatePerformanceCriterias(
                    criterias,
                    agencyId
                );
            logEvent(AMPLITUDE_EVENT_TYPES.ADD_RATING_CRITERIA);
            updateState &&
                dispatch(updatePerformanceCriteriasSuccess(updatedCriterias));
            dispatch(
                showNotification(
                    AGENCY_MESSAGES.UPDATE_CRITERIA_SUCCESS,
                    NOTIFICATION_TYPES.SUCCESS
                )
            );
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.UPDATE_CRITERIA_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const uploadOnboardingDocuments =
    (agencyId, onboardingData, updateState = true) =>
    async (dispatch) => {
        try {
            const uploadedDocuments =
                await agencyService.uploadImportantDocuments(
                    agencyId,
                    onboardingData
                );
            updateState &&
                dispatch(updateImportantDocumentsSuccess(uploadedDocuments));
            dispatch(
                showNotification(
                    AGENCY_MESSAGES.UPDATE_IMPORTANT_DOCUMENTS_SUCCESS,
                    NOTIFICATION_TYPES.SUCCESS
                )
            );
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.UPLOAD_IMPORTANT_DOCUMENTS_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const deleteImportantDocuments =
    (agencyId, importnatDocument, updateState = true) =>
    async (dispatch) => {
        try {
            const importantDocuments =
                await agencyService.deleteImportantDocument(
                    agencyId,
                    importnatDocument
                );
            updateState &&
                dispatch(updateImportantDocumentsSuccess(importantDocuments));
            dispatch(
                showNotification(
                    AGENCY_MESSAGES.DELETE_IMPORTANT_DOCUMENTS_SUCCESS,
                    NOTIFICATION_TYPES.SUCCESS
                )
            );
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.DELETE_IMPORTANT_DOCUMENTS_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const downloadImportantDocuments =
    (agencyId, documentId, fileName) => async (dispatch) => {
        try {
            await agencyService.downloadImportantDocuments(
                agencyId,
                documentId,
                fileName
            );
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.DOWNLOAD_IMPORTANT_DOCUMENTS_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const fetchAgencyImportantDocuments =
    (agencyId, query) => async (dispatch) => {
        try {
            const result = await agencyService.fetchImportantDocuments(
                agencyId,
                query
            );
            dispatch(fetchImportantDocumentsSuccess(result.importantDocuments));
            return result.totalCount;
        } catch (error) {
            if (error.code === HTTP_STATUS_CODES.UNAUTHORIZED) {
                dispatch(
                    authorizedShowNotification(error)(
                        error.message,
                        NOTIFICATION_TYPES.ERROR
                    )
                );
            } else {
                dispatch(
                    authorizedShowNotification(error)(
                        AGENCY_MESSAGES.DOWNLOAD_IMPORTANT_DOCUMENTS_FAIL,
                        NOTIFICATION_TYPES.ERROR
                    )
                );
            }
        }
    };

export const addTraining =
    (agencyId, training, updateState = true) =>
    async (dispatch) => {
        try {
            const newTraining = await agencyService.addTraining(
                agencyId,
                training
            );
            updateState && dispatch(addAgencyTrainingSuccess(newTraining));
            dispatch(
                showNotification(
                    AGENCY_MESSAGES.ADD_TRAINING_SUCCESS,
                    NOTIFICATION_TYPES.SUCCESS
                )
            );
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.ADD_TRAINING_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const updateTraining =
    (agencyId, trainingId, training, updateState = true) =>
    async (dispatch) => {
        try {
            await agencyService.updateTraining(agencyId, trainingId, training);
            updateState &&
                dispatch(updateAgencyTrainingSuccess(training, trainingId));
            dispatch(
                showNotification(
                    AGENCY_MESSAGES.UPDATE_TRAINING_SUCCESS,
                    NOTIFICATION_TYPES.SUCCESS
                )
            );
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.UPDATE_TRAINING_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const uploadTrainingFiles =
    (agencyId, trainingId, topicId, filesData) => async (dispatch) => {
        try {
            const updatedTraining = await agencyService.uploadTrainingFiles(
                agencyId,
                trainingId,
                topicId,
                filesData
            );
            dispatch(updateAgencyTrainingSuccess(updatedTraining, trainingId));
            dispatch(
                showNotification(
                    AGENCY_MESSAGES.UPLOAD_TRAINING_FILES_SUCCESS,
                    NOTIFICATION_TYPES.SUCCESS
                )
            );
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.UPLOAD_TRAINING_FILES_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const downloadTrainingFile =
    (agencyId, trainingId, topicId, fullFileName, saveAsName) =>
    async (dispatch) => {
        try {
            await agencyService.downloadTrainingFile(
                agencyId,
                trainingId,
                topicId,
                fullFileName,
                saveAsName
            );
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.DOWNLOAD_TRAINING_FILE_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const deleteTrainingFile =
    (agencyId, trainingId, topicId, fullFileName) => async (dispatch) => {
        try {
            const updatedTraining = await agencyService.deleteTrainingFile(
                agencyId,
                trainingId,
                topicId,
                fullFileName
            );
            dispatch(updateAgencyTrainingSuccess(updatedTraining, trainingId));
            dispatch(
                showNotification(
                    AGENCY_MESSAGES.DELETE_TRAINING_FILE_SUCCESS,
                    NOTIFICATION_TYPES.SUCCESS
                )
            );
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.DELETE_TRAINING_FILE_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const removeTraining =
    (agencyId, trainingId, updateState = true) =>
    async (dispatch) => {
        try {
            await agencyService.removeTraining(agencyId, trainingId);
            updateState &&
                dispatch(removeAgencyTrainingSuccess({ _id: trainingId }));
            dispatch(
                showNotification(
                    AGENCY_MESSAGES.DELETE_TRAINING_SUCCESS,
                    NOTIFICATION_TYPES.SUCCESS
                )
            );
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.DELETE_TRAINING_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const fetchTrainings = (agencyId, query) => async (dispatch) => {
    try {
        const result = await agencyService.fetchTrainings(agencyId, query);
        dispatch(fetchAgencyTrainingsSuccess(result.trainings));
        return result.totalCount;
    } catch (error) {
        dispatch(
            authorizedShowNotification(error)(
                AGENCY_MESSAGES.FETCH_TRAININGS_FAIL,
                NOTIFICATION_TYPES.ERROR
            )
        );
    }
};

export const fetchTraining = (agencyId, trainingId) => async (dispatch) => {
    dispatch(showApplicationLoader());
    try {
        const training = await agencyService.fetchTraining(
            agencyId,
            trainingId
        );
        dispatch(fetchIndividualTrainingSuccess(training));
    } catch (error) {
        dispatch(
            authorizedShowNotification(error)(
                AGENCY_MESSAGES.FETCH_TRAININGS_FAIL,
                NOTIFICATION_TYPES.ERROR
            )
        );
    } finally {
        dispatch(hideApplicationLoader());
    }
};

export const addTrainingTopic =
    (agencyId, trainingId, topic) => async (dispatch) => {
        try {
            const newTopic = await agencyService.addTrainingTopic(
                agencyId,
                trainingId,
                topic
            );
            dispatch(addTrainingTopicSuccess(newTopic, trainingId));
            dispatch(
                showNotification(
                    AGENCY_MESSAGES.ADD_TRAINING_TOPIC_SUCCESS,
                    NOTIFICATION_TYPES.SUCCESS
                )
            );
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.ADD_TRAINING_TOPIC_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const updateTrainingTopicDescription =
    (agencyId, trainingId, topicId, data) => async (dispatch) => {
        try {
            await agencyService.updateTrainingTopicDescription(
                agencyId,
                trainingId,
                topicId,
                data
            );
            dispatch(updateTrainingTopicSuccess(data, trainingId, topicId));
            dispatch(
                showNotification(
                    AGENCY_MESSAGES.UPDATE_TRAINING_TOPIC_SUCCESS,
                    NOTIFICATION_TYPES.SUCCESS
                )
            );
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.UPDATE_TRAINING_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const updateTrainingTopic =
    (agencyId, trainingId, topicId, data) => async (dispatch) => {
        try {
            await agencyService.updateTrainingTopic(
                agencyId,
                trainingId,
                topicId,
                data
            );
            dispatch(updateTrainingTopicSuccess(data, trainingId, topicId));
            dispatch(
                showNotification(
                    AGENCY_MESSAGES.UPDATE_TRAINING_TOPIC_SUCCESS,
                    NOTIFICATION_TYPES.SUCCESS
                )
            );
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.UPDATE_TRAINING_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const removeTrainingTopic =
    (agencyId, trainingId, topicId) => async (dispatch) => {
        try {
            await agencyService.removeTrainingTopic(
                agencyId,
                trainingId,
                topicId
            );
            dispatch(removeTrainingTopicSuccess({ _id: topicId }));
            dispatch(
                showNotification(
                    AGENCY_MESSAGES.REMOVE_TRAINING_TOPIC_SUCCESS,
                    NOTIFICATION_TYPES.SUCCESS
                )
            );
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.REMOVE_TRAINING_TOPIC_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };
export const addTrainingTopicVideo =
    (agencyId, trainingId, topicId, videoId) => async (dispatch) => {
        try {
            const updatedTraining = await agencyService.addTrainingTopicVideo(
                agencyId,
                trainingId,
                topicId,
                videoId
            );
            dispatch(updateAgencyTrainingSuccess(updatedTraining, trainingId));
            dispatch(
                showNotification(
                    AGENCY_MESSAGES.ADD_TRAINING_TOPIC_VIDEO_SUCCESS,
                    NOTIFICATION_TYPES.SUCCESS
                )
            );
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.ADD_TRAINING_TOPIC_VIDEO_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const removeTrainingTopicVideo =
    (agencyId, trainingId, topicId, videoId) => async (dispatch) => {
        try {
            const updatedTraining =
                await agencyService.removeTrainingTopicVideo(
                    agencyId,
                    trainingId,
                    topicId,
                    videoId
                );
            dispatch(updateAgencyTrainingSuccess(updatedTraining, trainingId));
            dispatch(
                showNotification(
                    AGENCY_MESSAGES.DELETE_TRAINING_TOPIC_VIDEO_SUCCESS,
                    NOTIFICATION_TYPES.SUCCESS
                )
            );
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.DELETE_TRAINING_TOPIC_VIDEO_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const fetchAgencyTimesheets = (agencyId, query) => async (dispatch) => {
    try {
        const queryAsObj = parseQueryString(query);
        const month = queryAsObj.date;

        const result = await agencyService.fetchTimeManagementTimesheets(
            agencyId,
            month,
            query
        );

        dispatch(fetchAgencyTimesheetsSuccess(result.timesheets));

        return result.totalCount;
    } catch (error) {
        if (error.code === HTTP_STATUS_CODES.UNAUTHORIZED) {
            dispatch(
                authorizedShowNotification(error)(
                    error.message,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        } else {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.FETCH_TIMESHEETS_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    }
};

export const exportAgencyTimesheets =
    (employees, months, agencyId, trackedMonths) => async (dispatch) => {
        try {
            const agencyInfo = await agencyService.fetchAgencyInfo(agencyId);

            let fileName = '';
            let trackedMonthsFileName =
                typeof months !== 'string'
                    ? months[0].split('-').reverse().join('-')
                    : months;
            if (months.length > 1 && typeof months !== 'string') {
                let { 0: startDate, [months.length - 1]: endDate } = months;
                startDate = startDate.split('-').reverse().join('-');
                endDate = endDate.split('-').reverse().join('-');
                trackedMonthsFileName = `${startDate}_${endDate}`;
            }

            if (employees.length === 1) {
                const { projectNames } = trackedMonths.trackedProjects[0];

                const hashDataForId =
                    projectNames.length > 0
                        ? `${employees[0]._id}${projectNames[0]._id}${trackedMonthsFileName}`
                        : `${employees[0]._id}${trackedMonthsFileName}`;

                const tenDigitHashId = generateNumericHash(hashDataForId);

                fileName =
                    projectNames.length < 2 &&
                    (months.length < 2 || typeof months == 'string')
                        ? `${tenDigitHashId}-${trackedMonthsFileName}-${
                              agencyInfo.name
                          }${
                              projectNames[0]?.name
                                  ? `-${projectNames[0].name}`
                                  : ''
                          }`
                        : `${trackedMonthsFileName}-${agencyInfo.name}-Timesheets`;
            } else {
                fileName = `${trackedMonthsFileName}-${agencyInfo.name}-Timesheets`;
            }

            dispatch(
                showNotification(
                    DOCUMENTS_MESSAGES.EXPORT_DOCUMENTS_DATA_STARTED,
                    NOTIFICATION_TYPES.INFO
                )
            );

            await agencyService.getExportedTimesheets(
                agencyId,
                employees.map((x) => x._id),
                months,
                fileName,
                trackedMonths
            );

            dispatch(exportAgencyTimesheetsSuccess());
            dispatch(
                showNotification(
                    AGENCY_MESSAGES.EXPORT_TIMESHEETS_SUCCESS,
                    NOTIFICATION_TYPES.SUCCESS
                )
            );
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.EXPORT_TIMESHEETS_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const fetchPendingHolidays =
    (agencyId, query, replaceState) => async (dispatch) => {
        try {
            const result = await agencyService.fetchPendingHolidays(
                agencyId,
                query
            );
            if (replaceState) {
                dispatch(replacePendingHolidaysSuccess(result.holidays));
            } else {
                dispatch(fetchPendingHolidaysSuccess(result.holidays));
            }
            return result.totalCount;
        } catch (error) {
            if (error.code === HTTP_STATUS_CODES.UNAUTHORIZED) {
                dispatch(
                    authorizedShowNotification(error)(
                        error.message,
                        NOTIFICATION_TYPES.ERROR
                    )
                );
            } else {
                dispatch(
                    authorizedShowNotification(error)(
                        HOLIDAYS_MESSAGES.FETCH_HOLIDAY_REQUESTS_FAIL,
                        NOTIFICATION_TYPES.ERROR
                    )
                );
            }
        }
    };

export const rejectHolidayRequest =
    (agencyId, request, updateState = true) =>
    async (dispatch) => {
        const type = HOLIDAY_REQUEST_CONSTANTS.HOLIDAY_REJECT_REQUEST_TYPE;
        const employeeIds = [request.employeeId];

        try {
            if (
                request.type === HOLIDAY_TYPES.PAID ||
                request.type === HOLIDAY_TYPES.UNPAID
            ) {
                dispatch(
                    showNotification(
                        HOLIDAYS_MESSAGES.START_GENERATING_PDFS,
                        NOTIFICATION_TYPES.INFO,
                        null,
                        NOTIFICATION_CLICKAWAY.DISABLED
                    )
                );
            }

            const result = await agencyService.updateHolidayRequests(
                agencyId,
                type,
                {
                    requests: [request],
                    employeeIds,
                    rejectionReason: request.rejectionReason,
                }
            );

            if (updateState) {
                dispatch(rejectHolidayRequestSuccess(request));
                dispatch(agencyHolidaysRequestsUpdateSuccess(result.data));
            }
            if (
                request.type === HOLIDAY_TYPES.PAID ||
                request.type === HOLIDAY_TYPES.UNPAID
            ) {
                dispatch(
                    showNotification(
                        HOLIDAYS_MESSAGES.PDFS_GENERATED_SUCCESS,
                        NOTIFICATION_TYPES.SUCCESS,
                        null,
                        NOTIFICATION_CLICKAWAY.DISABLED
                    )
                );
            } else {
                dispatch(
                    showNotification(
                        HOLIDAYS_MESSAGES.REJECT_HOLIDAY_REQUEST_SUCCESS,
                        NOTIFICATION_TYPES.SUCCESS,
                        null,
                        NOTIFICATION_CLICKAWAY.DISABLED
                    )
                );
            }
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    error.message,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const approveHolidayRequests =
    (agencyId, requests, from, to, updateState = true) =>
    async (dispatch) => {
        const type = HOLIDAY_REQUEST_CONSTANTS.HOLIDAY_APPROVE_REQUEST_TYPE;
        const employeeIds = requests.map((x) => x.employeeId);

        try {
            if (
                requests[0].type === HOLIDAY_TYPES.PAID ||
                requests[0].type === HOLIDAY_TYPES.UNPAID
            ) {
                dispatch(
                    showNotification(
                        HOLIDAYS_MESSAGES.START_GENERATING_PDFS,
                        NOTIFICATION_TYPES.INFO,
                        null,
                        NOTIFICATION_CLICKAWAY.DISABLED
                    )
                );
            }

            console.log(
                'updateHolidayRequests ---> agencyActions.js',
                agencyId,
                type,
                {
                    requests,
                    employeeIds,
                    from,
                    to,
                }
            );
            const approvedHolidays = await agencyService.updateHolidayRequests(
                agencyId,
                type,
                {
                    requests,
                    employeeIds,
                    from,
                    to,
                }
            );

            dispatch(approveHolidayRequestsSuccess(approvedHolidays.data));
            dispatch(
                agencyHolidaysRequestsUpdateSuccess(approvedHolidays.data)
            );
            if (approvedHolidays.errorMessage) {
                console.log('testing', JSON.stringify(approvedHolidays, 2));
                dispatch(
                    showNotification(
                        approvedHolidays.errorMessage,
                        NOTIFICATION_TYPES.ERROR
                    )
                );
            } else if (
                requests[0].type === HOLIDAY_TYPES.PAID ||
                requests[0].type === HOLIDAY_TYPES.UNPAID
            ) {
                dispatch(
                    showNotification(
                        HOLIDAYS_MESSAGES.PDFS_GENERATED_SUCCESS,
                        NOTIFICATION_TYPES.SUCCESS,
                        null,
                        NOTIFICATION_CLICKAWAY.DISABLED
                    )
                );
            } else {
                dispatch(
                    showNotification(
                        HOLIDAYS_MESSAGES.APPROVE_HOLIDAY_REQUEST_SUCCESS,
                        NOTIFICATION_TYPES.SUCCESS,
                        null,
                        NOTIFICATION_CLICKAWAY.DISABLED
                    )
                );
            }
        } catch (error) {
            console.log('testing', JSON.stringify(error, 2));
            dispatch(
                authorizedShowNotification(error)(
                    error.message,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const fetchAgencyPerformanceCriterias =
    (agencyId, query) => async (dispatch) => {
        try {
            const result = await agencyService.getAgencyPerformanceCriterias(
                agencyId,
                query
            );
            dispatch(
                fetchPerformanceCriteriasSuccess(result.performanceCriterias)
            );
            return result.totalCount;
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.FETCH_PERFORMANCE_CRITERIAS_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const fetchReviewedHolidays =
    (agencyId, query, replaceState) => async (dispatch) => {
        try {
            const result = await agencyService.fetchReviewedHolidays(
                agencyId,
                query
            );
            if (replaceState) {
                dispatch(replaceReviewedHolidaysSuccess(result.holidays));
            } else {
                dispatch(fetchReviewedHolidaysSuccess(result.holidays));
            }
            return result.totalCount;
        } catch (error) {
            if (error.code === HTTP_STATUS_CODES.UNAUTHORIZED) {
                dispatch(
                    authorizedShowNotification(error)(
                        error.message,
                        NOTIFICATION_TYPES.ERROR
                    )
                );
            } else {
                dispatch(
                    authorizedShowNotification(error)(
                        HOLIDAYS_MESSAGES.FETCH_HOLIDAY_REQUESTS_FAIL,
                        NOTIFICATION_TYPES.ERROR
                    )
                );
            }
        }
    };

export const fetchApprovedHolidays =
    (agencyId, from, to) => async (dispatch) => {
        try {
            const holidays = await agencyService.fetchApprovedHolidays(
                agencyId,
                from,
                to
            );
            dispatch(fetchApprovedHolidaysSuccess(holidays));
        } catch (error) {
            if (error.code === HTTP_STATUS_CODES.UNAUTHORIZED) {
                dispatch(
                    authorizedShowNotification(error)(
                        error.message,
                        NOTIFICATION_TYPES.ERROR
                    )
                );
            } else {
                dispatch(
                    authorizedShowNotification(error)(
                        HOLIDAYS_MESSAGES.FETCH_HOLIDAY_REQUESTS_FAIL,
                        NOTIFICATION_TYPES.ERROR
                    )
                );
            }
        }
    };

export const fetchSickLeaves = (agencyId, from, to) => async (dispatch) => {
    try {
        dispatch(sickLeavesDataFetchingSuccess());

        const sickLeaves = await agencyService.fetchSickLeaves(
            agencyId,
            from,
            to
        );

        dispatch(fetchSickLeavesSuccess(sickLeaves));

        return sickLeaves;
    } catch (error) {
        if (error.code === HTTP_STATUS_CODES.UNAUTHORIZED) {
            dispatch(
                authorizedShowNotification(error)(
                    error.message,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        } else {
            dispatch(
                authorizedShowNotification(error)(
                    HOLIDAYS_MESSAGES.FETCH_HOLIDAY_REQUESTS_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    } finally {
        dispatch(sickLeavesDataFetchSuccess());
    }
};

const FETCH_INFO_MAPPER = {
    [ROLES.ADMIN]: fetchAgencyHomeInformationSuccess,
    [ROLES.CLIENT]: fetchClientHomeInformationSuccess,
    [ROLES.EMPLOYEE]: fetchEmployeeHomeInformationSuccess,
    [ROLES.SUPPLIER_ADMIN]: fetchSupplierHomeInformationSuccess,
    [ROLES.SUPPLIER_EMPLOYEE]: fetchEmployeeHomeInformationSuccess,
    [ROLES.RECRUITER]: fetchRecruiterHomeInformationSuccess,
    [ROLES.CANDIDATE]: fetchEmployeeHomeInformationSuccess,
};

export const fetchHomeInformation =
    (agencyId, userId, displayMode) => async (dispatch) => {
        try {
            const userData = { userId, role: displayMode };

            const {
                isClientsInvoicingStatusDone,
                hasClients,
                isOnboardingPassed,
                agencyName,
                agencyUiDependencies,
                ...homeInformation
            } = await agencyService.fetchHomeInformation(agencyId, userData);

            const menuInformation =
                displayMode === ROLES.ADMIN
                    ? {
                          adminName: homeInformation.adminName || '',
                          agencyName: agencyName || '',
                          logoImgUrl: homeInformation.logoImgUrl || '',
                      }
                    : homeInformation;

            const pendingHolidays = homeInformation.pendingHolidaysCount || [];

            displayMode &&
                dispatch(FETCH_INFO_MAPPER[displayMode](menuInformation));

            dispatch(fetchAgencyUiDependenciesSuccess(agencyUiDependencies));

            if (
                displayMode === ROLES.ADMIN ||
                displayMode === ROLES.SUPPLIER_ADMIN
            ) {
                dispatch(
                    fetchClientsInvoicingStatusDoneSuccess({
                        isClientsInvoicingStatusDone,
                        hasClients,
                    })
                );

                dispatch(fetchPendingHolidaysCountSuccess(pendingHolidays));
            }

            if (displayMode === ROLES.EMPLOYEE) {
                const sampleSize = 2;
                const sample = await employeeService.fetchKnowYourTeamSample(
                    agencyId,
                    userId,
                    sampleSize
                );
                dispatch(fetchKnowYourTeamSampleOnLoginSuccess(sample));
            }
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.FETCH_HOME_INFORMATION_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const updateHomeInformation =
    (agencyId, userId, updateData, gotoUrl, role) => async (dispatch) => {
        try {
            const updatedDataResult = await agencyService.updateHomeInformation(
                agencyId,
                userId,
                { updateData }
            );

            if (updatedDataResult.isOnboardingPassed) {
                dispatch(updateOnboardingSuccess(updatedDataResult));
                const homePath = getHomePath(gotoUrl);
                dispatch(push(homePath.pathname));
            }

            dispatch(
                showNotification(
                    role !== ROLES.CANDIDATE
                        ? AUTH_MESSAGES.ONBOARDING_UPDATE_SUCCESS
                        : AUTH_MESSAGES.ONBOARDING_UPDATE_SUCCESS_CANDIDATE,
                    NOTIFICATION_TYPES.SUCCESS
                )
            );
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    error.message,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const fetchAdmins = (agencyId, query) => async (dispatch) => {
    try {
        const result = await agencyService.fetchAdmins(agencyId, query);
        dispatch(fetchAdminsSuccess(result.admins));
        return result.totalCount;
    } catch (error) {
        dispatch(
            authorizedShowNotification(error)(
                error.message,
                NOTIFICATION_TYPES.ERROR
            )
        );
    }
};

export const fetchAgencyProjects = (agencyId) => async (dispatch) => {
    dispatch(showApplicationLoader());
    try {
        const agencyProjects = await agencyService.fetchAgencyProjects(
            agencyId
        );
        dispatch(agencyProjectsFetchSuccess(agencyProjects));
    } catch (error) {
        dispatch(
            authorizedShowNotification(error)(
                AGENCY_MESSAGES.FETCH_AGENCY_PROJECTS_FAIL,
                NOTIFICATION_TYPES.ERROR
            )
        );
    } finally {
        dispatch(hideApplicationLoader());
    }
};

export const fetchAgencyTasks = (agencyId) => async (dispatch) => {
    try {
        dispatch(tasksDataFetchingSuccess());
        const tasks = await agencyService.fetchAgencyTasks(agencyId);

        dispatch(fetchAgencyTasksSuccess(tasks));
    } catch (error) {
        dispatch(
            authorizedShowNotification(error)(
                TASK_MESSAGES.FETCH_TASKS_FAIL,
                NOTIFICATION_TYPES.ERROR
            )
        );
    } finally {
        dispatch(tasksDataFetchSuccess());
    }
};

export const editTask = (task, employeeId, agencyId) => async (dispatch) => {
    try {
        const editedTask = await agencyService.updateTask(agencyId, task);

        dispatch(editTaskSuccess(editedTask));
        dispatch(
            showNotification(
                TASK_MESSAGES.EDIT_TASK_SUCCESS,
                NOTIFICATION_TYPES.SUCCESS
            )
        );
    } catch (error) {
        dispatch(
            authorizedShowNotification(error)(
                TASK_MESSAGES.EDIT_TASK_FAIL,
                NOTIFICATION_TYPES.ERROR
            )
        );
    }
};

export const deleteTask = (agencyId, taskId) => async (dispatch) => {
    try {
        const deletedTaskId = await agencyService.deleteTask(agencyId, taskId);

        dispatch(deleteTaskSuccess(deletedTaskId));
        dispatch(
            showNotification(
                TASK_MESSAGES.DELETE_TASK_SUCCESS,
                NOTIFICATION_TYPES.SUCCESS
            )
        );
    } catch (error) {
        dispatch(
            authorizedShowNotification(error)(
                TASK_MESSAGES.DELETE_TASK_FAIL,
                NOTIFICATION_TYPES.ERROR
            )
        );
    }
};

export const createTask = (task, employeeId, agencyId) => async (dispatch) => {
    try {
        const newTask = await agencyService.createTask(agencyId, task);

        logEvent(AMPLITUDE_EVENT_TYPES.ADD_TASK);
        dispatch(createTaskSuccess(newTask));
        dispatch(
            showNotification(
                TASK_MESSAGES.CREATE_TASK_SUCCESS,
                NOTIFICATION_TYPES.SUCCESS
            )
        );
    } catch (error) {
        dispatch(
            authorizedShowNotification(error)(
                TASK_MESSAGES.CREATE_TASK_FAIL,
                NOTIFICATION_TYPES.ERROR
            )
        );
    }
};

export const saveTask = (task, employeeId, agencyId) => (dispatch) => {
    task._id
        ? dispatch(editTask(task, employeeId, agencyId))
        : dispatch(createTask(task, employeeId, agencyId));
};

export const fetchAgencyDashboardInformation =
    (agencyId, from, to, fields, roles) => async (dispatch) => {
        try {
            dispatch(dashboardDataFetchingSuccess());

            const result = await agencyService.fetchAgencyDashboardInformation(
                agencyId,
                from,
                to,
                fields,
                roles
            );

            dispatch(
                fetchWorkLeavesSucces({
                    holidays: result.holidays,
                    sickLeaves: result.sickLeaves,
                })
            );
            dispatch(fetchFulltimePositionsSuccess(result.fulltimeJobs));
            dispatch(
                fetchAdminDashboardContractPositionsSuccess(result.contractJobs)
            );
            dispatch(
                fetchAdminDashboardEmployeesByCountrySuccess(
                    result.employeesByCountry
                )
            );
            dispatch(
                fetchVettedCandidatesCountSuccess(result.vettedCandidatesCount)
            );
            dispatch(
                fetchRejectedCandidatesCountSuccess(
                    result.rejectedCandidatesCount
                )
            );
            dispatch(
                fetchAdminDashboardPlacedEmployeesCountSuccess(
                    result.placedEmployeesCount
                )
            );
            dispatch(
                fetchAdminDashboardBenchedEmployeesCountSuccess(
                    result.benchedEmployeesCount
                )
            );
            dispatch(
                fetchAdminDashboardManagementEmployeesCountSuccess(
                    result.managementEmployeesCount
                )
            );
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    DASHBOARD_MESSAGES.FETCH_DASHBOARD_PAGE_INFORMATION_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        } finally {
            dispatch(dashboardDataFetchSuccess());
        }
    };

export const fetchAdminDashboardCandidates = (agencyId) => async (dispatch) => {
    try {
        const result = await agencyService.fetchAdminDashboardCandidates(
            agencyId
        );
        dispatch(fetchAdminDashboardCandidatesSuccess(result));
    } catch (error) {
        dispatch(
            authorizedShowNotification(error)(
                DASHBOARD_MESSAGES.FETCH_DASHBOARD_CANDIDATES_FAIL,
                NOTIFICATION_TYPES.ERROR
            )
        );
    } finally {
        dispatch(hideApplicationLoader());
    }
};

export const updateAgencyEvrotrustInformation =
    (agencyId, data) => async (dispatch) => {
        try {
            const result = await agencyService.updateAgencyEvrotrustInformation(
                agencyId,
                data
            );

            dispatch(updateAgencyEvrotrustInfoSuccess(result));
            dispatch(agencySettingsUpdateSuccess(result));
            dispatch(
                showNotification(
                    AGENCY_MESSAGES.UPDATE_EVROTRUST_SETTINGS_SUCCESS,
                    NOTIFICATION_TYPES.SUCCESS
                )
            );
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.UPDATE_EVROTRUST_SETTINGS_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const updateAgencyIsUsingEvrotrust =
    (agencyId, isUsingEvrotrust) => async (dispatch) => {
        try {
            const result = await agencyService.updateAgencyIsUsingEvrotrust(
                agencyId,
                { isUsingEvrotrust }
            );
            dispatch(updateAgencyIsUsingEvrotrustSuccess(result));
            dispatch(
                showNotification(
                    AGENCY_MESSAGES.UPDATE_IS_USING_EVROTRUST_SUCCESS,
                    NOTIFICATION_TYPES.SUCCESS
                )
            );
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.UPDATE_IS_USING_EVROTRUST_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const fetchAgencyEvrotrustInfo = (agencyId) => async (dispatch) => {
    try {
        const result = await agencyService.fetchAgencyEvrotrustInformation(
            agencyId
        );
        dispatch(fetchAgencyEvrotrustInfoSuccess(result));
    } catch (error) {
        dispatch(
            authorizedShowNotification(error)(
                AGENCY_MESSAGES.FETCH_EVROTRUST_SETTINGS_FAIL,
                NOTIFICATION_TYPES.ERROR
            )
        );
    }
};

export const fetchIsAgencyEvrotrustIntegrated =
    (agencyId) => async (dispatch) => {
        try {
            const result = await agencyService.fetchAgencyEvrotrustIntegration(
                agencyId
            );
            dispatch(fetchAgencyEvrotrustIntegrationSuccess(result));
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.FETCH_EVROTRUST_SETTINGS_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const updateAgencyCalendlyInformation =
    (agencyId, data) => async (dispatch) => {
        try {
            const result = await agencyService.updateAgencyCalendlyInformation(
                agencyId,
                data
            );

            dispatch(updateAgencyCalendlyInfoSuccess({ calendlyInfo: result }));
            dispatch(
                showNotification(
                    AGENCY_MESSAGES.UPDATE_CALENDLY_SETTINGS_SUCCESS,
                    NOTIFICATION_TYPES.SUCCESS
                )
            );
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.UPDATE_CALENDLY_SETTINGS_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const fetchAgencyCalendlyInfo = (agencyId) => async (dispatch) => {
    try {
        const result = await agencyService.fetchAgencyCalendlyInformation(
            agencyId
        );

        dispatch(fetchAgencyCalendlyInfoSuccess(result));
    } catch (error) {
        dispatch(
            authorizedShowNotification(error)(
                AGENCY_MESSAGES.FETCH_CALENDLY_SETTINGS_FAIL,
                NOTIFICATION_TYPES.ERROR
            )
        );
    }
};

export const fetchUserManagementPage =
    (agencyId, fields, roles, filters) => async (dispatch) => {
        dispatch(fetchEmployeesPending());
        try {
            const result = await agencyService.fetchUserManagementPage(
                agencyId,
                fields,
                roles,
                filters
            );

            dispatch(fetchEmployeesSuccess(result.employeesData));
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.FETCH_USER_MANAGEMENT_PAGE_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const updateTermsAndConditionsVersion =
    (agencyId) => async (dispatch) => {
        try {
            await agencyService.updateTermsAndConditionsVersion(agencyId);
            dispatch(
                termsAndConditionsUpdateSuccess({
                    isTermsAndConditionsAccepted: true,
                })
            );
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.UPDATE_TERMS_AND_CONDITIONS_VERSION_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const fetchTermsAndConditions = (agencyId) => async (dispatch) => {
    try {
        const result = await agencyService.fetchTermsAndConditions(agencyId);
        dispatch(fetchTermsAndConditionsSucces(result));
    } catch (error) {
        dispatch(
            authorizedShowNotification(error)(
                AGENCY_MESSAGES.GET_TERMS_AND_CONDITIONS_FAIL,
                NOTIFICATION_TYPES.ERROR
            )
        );
    }
};

export const fetchAgencyName = (agencyId) => async (dispatch) => {
    try {
        const result = await agencyService.fetchAgencyName(agencyId);

        dispatch(fetchAgencyNameSuccess(result));
    } catch (error) {
        dispatch(
            authorizedShowNotification(error)(
                AGENCY_MESSAGES.GET_AGENCY_NAME_FAIL,
                NOTIFICATION_TYPES.ERROR
            )
        );
    }
};

export const setAgencyName = (agencyName) => (dispatch) =>
    dispatch(fetchAgencyNameSuccess(agencyName));

export const fetchAgencyMainAdmin = (agencyId) => async (dispatch) => {
    try {
        const result = await agencyService.fetchAgencyMainAdmin(agencyId);

        dispatch(fetchAgencyMainAdminSucces(result));
    } catch (error) {
        dispatch(
            authorizedShowNotification(error)(
                AGENCY_MESSAGES.GET_AGENCY_MAIN_ADMIN_FAIL,
                NOTIFICATION_TYPES.ERROR
            )
        );
    }
};

export const fetchIsOnboardingPassed = (userId) => async (dispatch) => {
    dispatch(showApplicationLoader());

    try {
        const result = await agencyService.fetchIsOnboardingPassed(userId);
        dispatch(fetchOnboardingSuccess(result));
        return result;
    } catch (error) {
        dispatch(
            authorizedShowNotification(error)(
                AGENCY_MESSAGES.ONBOARDING_STATUS_FETCH_FAIL,
                NOTIFICATION_TYPES.ERROR
            )
        );
    } finally {
        dispatch(hideApplicationLoader());
    }
};

export const fetchMainMenuInformation =
    (agencyId, userId, role) => async (dispatch) => {
        try {
            const result = await agencyService.fetchMainMenuInformation(
                agencyId,
                userId,
                role
            );

            dispatch(fetchMainMenuInformationSuccess(result));
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    MAIN_MENU_MESSAGES.FETCH_MAIN_MENU_INFORMATION_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const fetchAgencySettings = (agencyId) => async (dispatch) => {
    try {
        const result = await agencyService.fetchAgencySettings(agencyId);

        dispatch(agencySettingsFetchSuccess(result));
    } catch (error) {
        dispatch(
            authorizedShowNotification(error)(
                AGENCY_MESSAGES.FETCH_AGENCY_SETTINGS_FAIL,
                NOTIFICATION_TYPES.ERROR
            )
        );
    }
};

export const updateAgencySettings =
    (agencyId, newSettings) => async (dispatch) => {
        try {
            const result = await agencyService.updateAgencySettings(
                agencyId,
                newSettings
            );

            dispatch(agencySettingsUpdateSuccess(result));
            dispatch(
                showNotification(
                    AGENCY_MESSAGES.AGENCY_SETTINGS_UPDATE_SUCCESS,
                    NOTIFICATION_TYPES.SUCCESS
                )
            );
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.AGENCY_SETTINGS_UPDATE_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };

export const generateSentryError = (agencyId) => async (dispatch) => {
    try {
        await agencyService.generateSentryError(agencyId);
    } catch (error) {
        dispatch(
            authorizedShowNotification(error)(
                AGENCY_MESSAGES.GENERATE_SENTRY_FAIL,
                NOTIFICATION_TYPES.ERROR
            )
        );
    }
};

export const fetchAgencyDeletedUsers =
    (agencyId, query, replaceState) => async (dispatch) => {
        try {
            const result = await agencyService.fetchAgencyDeletedUsers(
                agencyId,
                query
            );

            if (replaceState) {
                dispatch(replaceDeletedUsersSuccess(result.deletedUsers));
            } else {
                dispatch(fetchDeletedUsersSuccess(result.deletedUsers));
            }
            return result.totalCount;
        } catch (error) {
            dispatch(
                authorizedShowNotification(error)(
                    AGENCY_MESSAGES.FETCH_DELETED_USERS_FAIL,
                    NOTIFICATION_TYPES.ERROR
                )
            );
        }
    };
